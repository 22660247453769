import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import {Card, ListGroupItem, ListGroup, Container, Col, Row} from 'react-bootstrap';
import Wedding from "../assets/images/wedding.jpg";
import Coporate from "../assets/images/coporate.jpg"
import Party from "../assets/images/party.jpg";
import WeddingModal from "./WeddingModal";


const steps = [
  
  'Place an Order',
  'Order  Confirmed',
  'Food Is Cooked',
  'Pickup'
];
const useStyles = makeStyles(() => ({
    root: {
        paddingTop: "30px",
        paddingBottom: "30px",
      "& .MuiStepIcon-active": { color: "#FFB81C" },
      "& .MuiStepIcon-completed": { color: "#FFB81C" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "#FFB81C" }
    },
    
  }))
export default function HorizontalLabelPositionBelowStepper() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0)
    const [weddingModal, setWeddingModal] = useState(false);
    const stepper  = (step) =>{
        setActiveStep(step)
    }
    const hideModal = () =>{
      console.log("hide modal")
      setWeddingModal("FUCKING HELL")
    }


  return (
      <>
       <br />
      
      <Typography variant="h1">
    
      Nostalgia Kitchen
     </Typography>
     <div style={{margin: "auto",
  width: "50%",
  
  padding: "10px"}}>
   {/* <Button 
          sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          variant="contained" size="large" style={{  backgroundColor:"#FF3008", color:"white", borderRadius:"20px"}}>
          <a 
        href="https://order.online/business/nostalgia-kitchen-11102441/en-US" target="_blank"
        style={{color:"white", textDecoration:"none"}}
        > 
              Order Now
              </a>
            </Button> */}
   </div>

      <br />
    
      <div style={{width:"80%", margin: "auto",
         width: "100%", padding:"10px"}}>
      <Typography variant="h5">
        An authentic Goan restaurant, available for takeout and catering. <br /> We cater for Weddings, Birthdays and parties.
     </Typography>
      </div>
      <br />
      <div style={{width:"80%", margin: "auto",
         width: "100%", padding:"10px"}}>
      {/* <Typography variant="h5">
        Catering
     </Typography> */}
      </div>
      {/* <div
          style={{
            display:'flex',
            justifyContent:"space-around",
            flexWrap:"wrap",
            backgroundImage: `linear-gradient(
                5deg,
          #f6f6f6 50%,
          #f6f6f6 60%,
          #f6f6f6 50%
            )`,
            backgroundColor: "#FCDC5A",
            backgroundSize: "1em 1em",
            backgroundRepeat: "space",
            padding:"20px"
          }}
        >
            <Card style={{ width: '19rem' }}>
                <Card.Img 
                  variant="top" 
                  src={Wedding}
                  alt="Wedding catering"
                  style={{height:"35vh"}}
                 />
                <Card.Body>
                  <Card.Title>Wedding Catering</Card.Title>
                  <Card.Text style={{display:'flex', textAlign:"justify"}}>
                  Nostalgia Kitchen is excited to introduce their wedding catering services! 
                  Known for our authentic Goan cuisine, 
                  we offer a delightful menu featuring dishes like Goan Fish Curry, Xacuti, and Sorpatel. 
                  With our focus on high-quality ingredients and personalized service,
                   Nostalgia Kitchen promises to make your special day even more special. (apprx $70 per person)
                  </Card.Text>
                  
                  <WeddingModal />
                </Card.Body>
            </Card>
            <Card style={{ width: '19rem' }}>
                <Card.Img 
                  variant="top" 
                  src={Coporate}
                  alt="Coporate catering"
                  style={{height:"35vh"}}
                 />
                <Card.Body>
                  <Card.Title>Coporate Catering</Card.Title>
                  <Card.Text style={{display:'flex', textAlign:"justify"}}>
                  We specialize in corporate events, we offer everything from boxed lunches to full-service meals. 
                  Their customizable menus and reliable service ensure that your event runs smoothly.
                  </Card.Text>
                  
                  <WeddingModal />
                
                </Card.Body>
            
            </Card>
            <Card style={{ width: '19rem' }}>
                <Card.Img 
                  variant="top" 
                  src={Party}
                  alt="Party/events catering"
                  style={{height:"35vh"}}
                 />
                <Card.Body>
                  <Card.Title>Catering for Parties/Events</Card.Title>
                  <Card.Text style={{display:'flex', textAlign:"justify"}}>
                  We Deliver delicious goan food for your event/party. We sell by the trays 
                  </Card.Text>
                  
                  <WeddingModal />
                </Card.Body>
            
            </Card>
        </div> */}
     
     <Typography variant="h3">
         How It Work?
     </Typography>
 

      
    <Box sx={{ width:{ xs:"100%", md:"100%",}, backgroundColor:"white", padding:"10px" }} >
         
      <Stepper  className={classes.root} alternativeLabel style={{width:'100%'}} activeStep={4} >
        {steps.map((label) => (
        
          <Step key={label} >
            <StepLabel>
                <Typography >
                {label}
                </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
     
    
    </Box>
    </>
  );
}