import {Carousel, Image} from 'react-bootstrap'
import React from 'react';
import {useState} from 'react'
import OpenNow from '../assets/images/dazzle.png';
import Menu from '../assets/images/5menu.png';
import Biryani from '../assets/images/Biryani.png';
import tastebuds from '../assets/images/tastebuds.png';
import favSnack from '../assets/images/favSnack/favSnack -992.png';
import BillysBowl from '../assets/images/ricebowls/billybowl 992.png';
import Button from '@mui/material/Button';
import { GiChicken } from "react-icons/gi";
import { GiCorn } from "react-icons/gi";
import food from '../assets/images/food.png';
import { Link } from "react-router-dom";




function Hero2() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <div 
          style={{
            height:"80vh",
            display:"flex",
            // backgroundColor:"#f6f6f6",
            width:"100%",
          }}>
            <div style={{width:"70%", alignItems:"center", height:"100%"}}>
              <div style={{display:"flex", justifyContent:"center"}}>
                <h1 style={{alignItems:"center",textAlign:"left", fontWeight:"bold", fontSize:"6em", fontFamily:"inter"}}>
                   Six Dishes,<br /> Delivered <br /> right to your <br /> door
                    <Button size="large" variant="contained" 
                    style={{backgroundColor:"orange", marginLeft:"10px"}}>
                      <Link to="/weeklymenu" style={{textDecoration:"none", fontSize:"15px", color:"black", padding:"2px"}}>
                          Order now
                      </Link>
                      
                      </Button> <br />
                </h1>

              </div>
            </div>
       
            <div style={{ 
              // backgroundImage:`url(../assets/images/ricebowls/billybowl 992.png)`,
              width:"600px",
              height:"490px",
              borderRadius:"17px",
              // backgroundImage:`url(${tastebuds})`,
              backgroundImage:`url(${food})`,

              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              // backgroundColor:"purple" 
              // backgroundColor:"white",

            }}
              
            
            >
              
              {/* <img src={favSnack} /> */}
            </div>

      </div>
      {/* <div style={{display:"flex", justifyContent:"center", flexDirection:"column", marginTop:"5px"}}>
                <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
                    <div style={{textAlign:"left", marginBottom:"10px", fontSize:"25px"}}>
                      <div style={{borderBottom:"1px dashed black"}}><GiChicken size={30}/> Non-Vegetarian</div>
                          <div>Chicken Green Curry</div>
                          <div>Mutton Roce Curry</div>
                          <div>Pampana Red Curry</div>
                          <div>Prawn Masala</div>
                          <div style={{borderBottom:"1px dashed black"}}><GiCorn size={20}/> Vegetarian</div>
                          <div>Green Peas With Paneer</div>
                          <div>Red Kidney Beens</div>
                    </div>
                </div>
                  <div style={{display:'flex', justifyContent:"center"}}>
                      <Button variant="contained" style={{backgroundColor:"orange"}}>Order now</Button>
                  </div>
              </div> 
      <div
        style={{
          marginTop:"50px"
        }}
      >
          <Carousel.Item  interval={1500}>
              <Image
                src={tastebuds}
                alt="Indulge your taste buds with nostalgia"
                style={{
                
                  
                }}
                responsive="true"
              />
            
            </Carousel.Item>
            <Carousel.Item  interval={1500}>
              <Image
                src={favSnack}
                alt="amazing goan snack"
                style={{
                
                  
                }}
                responsive="true"
              />
            
            </Carousel.Item>
            <Carousel.Item  interval={1500}>
              <Image
                src={BillysBowl}
                alt="Billy's Bowl"
                style={{
                
                  
                }}
                responsive="true"
              />
            
            </Carousel.Item>
            
            {/*Correction <Carousel.Item interval={1500}>
              <Image
                className="d-block w-100"
                src={Menu}
                alt="Second slide"
                responsive="true"
                style={{
                  maxWidth:"100%",
                  height:"50%",
                  
                  
                }}
                
              />

            
            </Carousel.Item> 
            
          
          </Carousel>
      </div>*/}
            
    </>
  );
}

export default Hero2;



