import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Header from '../components/Header';
import Header2 from "../components/Common/Header"
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import { useLocation } from "react-router-dom";
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import { GiChicken } from "react-icons/gi";
import { GiCorn } from "react-icons/gi";
import CookieIcon from '@mui/icons-material/Cookie';
import { FaCookieBite } from "react-icons/fa6";
import { BiSolidFoodMenu } from "react-icons/bi";
import { MdFastfood } from "react-icons/md";
import { RiEmojiStickerLine } from "react-icons/ri";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.transparent,
 boxShadow: "none"
}));
const HomePage = () => {
  const location = useLocation(); 
  const [sizex, setSizeX] = useState("");
  const [sizePrice, setSizePrice] = useState()
  const [additionItemPrice, setadditionItemPrice] = useState(5);
  const [additionItem, setadditionItem] = useState(false)
  const [total, setTotal] = useState(0);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [unit_number, setunit_number] = useState("");
  const [postal_code, setpostal_code] = useState("");
  const [city, setCity] = useState("");
  const [addresserror, setAddresserror] = useState(false);
  const [unit_numbererror, setunit_numbererror] = useState(false);
  const [postal_codeerror, setpostal_codeerror] = useState(false);
  const [cityerror, setCityerror] = useState(false);
  const [pickup, setPickup] = useState(true);
  const [dishes, setDishes] = useState([]);
  const [weekly_menu_meals, set_weekly_menu_meals] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [meals, setMeals] = useState([]);
  const [turn_off_remove, set_turn_off_remove] = useState(false);
  const [turn_off_add, set_turn_off_add] = useState(false);
  const [not_successfull_substitution, set_not_successfull_substitution] = useState(false);
  const [nonveg, set_nonveg] = useState([{name:"Chicken Curry with salad for $7.50", quantity:1, price: 7.50 }, {name:"Veg Curry with salad for $6.50", quantity:0, price: 6.50 }]);
  const [veg, set_veg] = useState([]);
  const [additional_items, set_additional_items] = useState([]);
  const [disable_submit, set_disable_submit] = useState(false);
  const [taxes, setTaxes] = useState(0.13)



useEffect(()=>{
  calculateTotal()
},[additional_items, sizex, pickup, nonveg, veg])


  const calculateTotal = () => {
    let sumWithInitial = nonveg.reduce(
      (accumulator, currentValue) =>  accumulator + currentValue.quantity * currentValue.price,
      0,
    );

    setTotal(sumWithInitial)
   
  }

  useEffect(()=>{
    let sumWithInitial = nonveg.reduce(
        (accumulator, currentValue) =>  accumulator + currentValue.quantity,
        0,
      );
      console.log(sumWithInitial)
    if(sumWithInitial){
        set_disable_submit(false)
    } else {
        set_disable_submit(true)
    }
  },[nonveg])

  const submitOrder = (e) =>{
  
    if(name === ""){
      setNameError("Please Enter your name")
      return
    }
    if(email === ""){
      setEmailError("Please Enter your email")
      return
    }
    if(phone === ""){
      setPhoneError("Please Enter your Phone number")
      return
    }
    if(pickup === false){
        if(address === ""){
            setAddresserror("Please Enter your address")
            return
          }
          if(postal_code === ""){
            setpostal_codeerror("Please Enter your Postal Code")
            return
          }
          if(city === ""){
            setCityerror("Please Enter your City")
            return
          }
    }


    if(name && phone && email ){
      setLoading(true)
      let data = {}
         data = {
            name: name,
            phone: phone,
            email: email,
            tax:  (total*taxes).toFixed(2) ,
            orderSize: sizex,
            pickup: pickup ,
            total: (parseFloat(total) + (total*taxes)).toFixed(2),
            nonveg: nonveg,
            veg: veg,
            additional_items: additional_items,
            address: address,
            unit_number: unit_number,
            postal_code: postal_code,
            city: city,
            subtotal: total.toFixed(2)
            
          }
   
     


      var canContinue = true
    // fetch("http://localhost:4000/sendweeklymenu",{

      fetch("https://dsqqdxoqddpdvzqosdmm.supabase.co/functions/v1/send-email", {
            method: 'POST',
            // headers: {
            //  'Content-Type': 'application/json',
            //  "Access-Control-Origin": "*",
            //  'Access-Control-Allow-Origin': "*"
            // },
            body: JSON.stringify(data)
        })
      .then(response=>response.json())
      .then((data)=>{
        setLoading(false)
        setSuccess(true)
    

      
        
      }).catch(e=> {setLoading(false); alert(e)})
    // if(canContinue){

    //   fetch("https://admin.billfern.ca/sendOrder",{
    //     method: 'POST',
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(data)
      
    //   } ).then(response=>response.json()).then((data)=>
      
    //   {
    //     if(data.data === "success"){
    //           setLoading(false)
    //         setName("") 
    //         setEmail ("") 
    //         setPhone("") 
    //         setSuccess(true)
      
          
      
            
    //   }}
      
    //   ).catch((e)=>{console.log(e);setSuccess(false);  setLoading(false)})
    // }
    }
  }

  const increase_count = (i) =>{  
    if(meals[i].quantity <2){
     
      let temp_meals = meals
      temp_meals[i].quantity = temp_meals[i].quantity + 1
      setMeals([...temp_meals])
    
    
    } else {
      return 
    }

  }

  const decrease_count = (i) =>{  
    if(meals[i].quantity){
      let temp_meals = meals
      temp_meals[i].quantity = temp_meals[i].quantity - 1
      setMeals([...temp_meals])
    
    } else {
      return 
    }

  }



  return  (
    <>
    <Helmet >
<title>Nostalgia Kitchen - Lunch menu</title>
<link rel="notImportant" href="https://www.nostalgiakitchen.ca/lunch_special" />
<meta name="description"   content="View Our Lunch Menu" />
<link rel="canonical" href="https://www.nostalgiakitchen.ca/lunch_special" />
<meta property="og:title" content="Nostalgia Kitchen - Taste of Goa"/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="https://www.nostalgiakitchen.ca/lunch_special"/>

</Helmet> 
   
  <Box sx={{ width: '100%'}}> 
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
    <Grid item xs={12}>
          <Item sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
                    <Header2 />
                    {/* <Header /> */}
                 
                  </Item>
                  <Item sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <Header />
                 
                  </Item>
          {/* <Item>
            <Header /> 
            <Header2 />
          </Item> */}
        </Grid>
         <Grid item xs={12} style={{margin:"5px"}}>
                <div style={{display: !showMenu ? "none" : ""}}>
                    <div>
                    <div style={{display:"flex", justifyContent:"center"}}> 
                        <h1>Nostalgia's Rice & Curry Deal <br />
                            (Pick up Only)
                        </h1> <br />
                        
                    </div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                                <p>Lunch Special<br />
                            </p>
                        </div>


                          <div  style={{display:"flex", justifyContent:"center", width:"100%"}}>
                                  <Paper elevation={5} style={{width:"90%", maxWidth:"500px", padding:"10px"}}>
                                      <div style={{width:"100%", textAlign:"center", backgroundColor:"#ffc109", color:"black"}}>Step 1: Pick <b style={{fontSize:"1.4em"}}></b> curry</div>
                                     
                                     <table style={{width:"100%"}}>
                                          <tbody style={{width:'100%'}}>
                                              {nonveg && nonveg.map((el, i)=>(
                                                <tr style={{fontSize:"1.3", padding:"10px"}}>
                                                  <td>{el.name}</td>
                                                  <td>
                                                        {
                                                        <button 
                                                          style={{backgroundColor:"white", borderRadius:"10px", textAlign:"center"}}  
                                                            size="small"  variant="outlined"  
                                                            onClick={()=>{
                                                              let temp = nonveg;
                                                              if(temp[i].quantity){
                                                                temp[i].quantity = temp[i].quantity - 1
                                                                set_nonveg([...temp]) 
                                                              }
                                                            }}
                                                            disabled={( (el.quantity > 0)) ? false : true }

                                                        >
                                                          -
                                                        </button>
                                                        
                                                        } 
                                                     
                                                  </td> 
                                                  <td>{el.quantity}</td> 
                                                  <td>

                                                  { 
                                                            <button 
                                                            style={{backgroundColor:"white", borderRadius:"10px", textAlign:"center"}}  
                                                              // disabled={nonveg.reduce((a,b)=> a + b.quantity, 0) < 1 ?  false : true }
                                                              size="small"  
                                                              variant="outlined"  
                                                              onClick={()=>{
                                                                let temp = nonveg;
                                                                temp[i].quantity = temp[i].quantity + 1
                                                                set_nonveg([...temp]) 
                                                            }}
                                                            >
                                                              +
                                                            </button>
                                                            } 
                                                  </td>
                                                      
                                                </tr>))}
                                            
                                          </tbody> 

                                     </table>


                                        {/* 12-September-2023 -BF Deploy once server is available */}

                    <div 
                    style={{
                      margin:"10px",
                      fontSize:"1.5em",
                      fontWeight:"bold",
                      borderBottom:"2px solid #ffc107"
                    }}>
                      Pick up Info
                    </div>
                 
                     <Stack spacing={2}>
                            <div style={{ display:"flex", justifyContent:"center"}}>

                            <div style={{maxWidth:"50%", display:"flex", flexDirection:"column", gap:"5px"}}>
                                <TextField  size="small" id="standard-basic" label="Name" variant="outlined" error={nameError}  value={name} onChange={(e)=>{setName(e.target.value); setNameError("")}}/>     
                                <span style={{color:"red"}}>{nameError}</span>
                                <TextField size="small" id="standard-basic" label="Email" variant="outlined" error={emailError} value={email} onChange={(e)=>{setEmail(e.target.value); setEmailError("")}}/>   
                                <span style={{color:"red"}}>{emailError}</span>
                                <TextField size="small" id="standard-basic" label="Phone Number" error={phoneError} value={phone} variant="outlined" onChange={(e)=>{setPhone(e.target.value); setPhoneError("")}}/>    
                                <span style={{color:"red"}}>{phoneError}</span>
                            </div>

                            </div>
                          
                            <Box sx={{ minWidth: 200 }}>
                            <div style={{display:"flex", justifyContent:"center", margin:"5px"}}>
                                            <input type="radio" 
                                                    id="pickup"
                                                    name="typeofdelvery"
                                                    value="16oz" 
                                                        onChange={(event) => setPickup(true)} inline
                                                        checked={pickup === true} 
                                                        />
                                                        
                                            <label htmlFor="pickup">Pick up in store</label>
                                            </div>
                                     
                    
                        </Box>
                    </Stack> 
      <div style={{display:"flex", justifyContent:"center"}}>
                            <div style={{borderTop:"1px black dashed", borderBottom:"1px black dashed", padding:"5px"}}>
                            
                                Sub-Total: ${total.toFixed(2)} <br />
                                {pickup === false && <s>"Delivery Charge: $6"</s>}
                                {pickup === false && <br />}
                                tax: ${(parseFloat(total) *taxes).toFixed(2)}
                                 <br />
                               <b>Total: ${(parseFloat(total)  + ((parseFloat(total) *taxes))).toFixed(2) }</b> <br />
                                   {/*<Button style={{
                                     backgroundColor:"#8B0000",
                                     color:"white",
                                     marginRight:"10px",
                                     marginBottom:"10px",
                                     border: '1px black solid',
                                     borderRadius:"0px"
                                }}
                                onClick={ ()=>w
                                // onClick={ ()=>submitOrder()}indow.open('tel:647-863-9249')}

                                >Call 647-863-9249 to place order</Button> */}
                            </div>
                         </div> 
                <div style={{display:'flex', justifyContent:'center'}}>
                      {/* 12-September-2023 -BF Deploy once server is available */}
          {loading ? <CircularProgress style={{color:"#8B0000"}}/> : 
                <Button style={{
                                     backgroundColor: disable_submit ? "gray" : "#8B0000",
                                     color: "white",
                                     marginRight:"10px",
                                     marginBottom:"10px",
                                     border: '1px black solid',
                                     borderRadius:"0px",
                                     display: success ? "none" : ""
                                    }}
                                    disabled={success || disable_submit}
                                // onClick={ ()=>window.open('tel:647-863-9249')}
                                onClick={ ()=>submitOrder()}

                                >Submit Order</Button>} 
             </div>
             <div style={{display:"flex", justifyContent:"center"}}>
                        {success && "Your Order was placed successfully. Please wait for a confirmation call. "}
             </div>
 
                                  </Paper>

                          </div>



                       
                
                      
                       



                    </div>

                </div>
        </Grid>
        <div style={{display: showMenu ? "none" :'flex', justifyContent:"center", textAlign:"center", width:"100%", padding:"20px"}}>
                                    <b>Unfortunately, this Menu is no longer available, please wait for next week's menu or contact nostalgia to check for availability.</b>
        </div>
       
    <Footer />
    </Grid>   
  </Box>
  </>
  )
};

export default HomePage;
